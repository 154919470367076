<template>
  <div class="row justify-content-center my-5">
    <div class="card col-12 col-md-6 col-lg-4">
      <DualRingLoader :loading="loading">
        <div class="card-body">
          <!-- <form v-if="loaded" class @submit.prevent="Submit">
            <div class="mb-3">
              <div>
                <label for="response" class="form-label">Response</label>
                <InputWrapper :errors="formError.response">
                  <textarea
                    rows="3"
                    type="text"
                    v-model="formData.response"
                    class="form-control"
                    id="response"
                    required
                  />
                </InputWrapper>
              </div>
            </div>

            <div class="text-center">
              <button class="btn btn-dark">Update Response</button>
            </div>
          </form>-->
          <div v-if="loaded" class="card-body">
            <form class @submit.prevent="Submit">
              <div class="mb-3">
                <div>
                  <label for="level" class="form-label">Level</label>
                  <InputWrapper :errors="formError.level">
                    <input
                      type="number"
                      v-model="formData.level"
                      class="form-control"
                      id="level"
                      required
                      disabled
                    />
                  </InputWrapper>
                </div>
              </div>
              <div class="mb-3">
                <div>
                  <label
                    for="cummulativeWithdrawerLimit"
                    class="form-label"
                  >Cummulative Withdrawal Limit</label>
                  <InputWrapper :errors="formError.cummulativeWithdrawerLimit">
                    <input
                      type="number"
                      v-model="formData.cummulativeWithdrawerLimit"
                      class="form-control"
                      id="cummulativeWithdrawerLimit"
                      required
                    />
                  </InputWrapper>
                </div>
              </div>
              <div class="mb-3">
                <div>
                  <label for="dailyWithdrawerLimit" class="form-label">Daily Withdrawal Limit</label>
                  <InputWrapper :errors="formError.dailyWithdrawerLimit">
                    <input
                      type="number"
                      v-model="formData.dailyWithdrawerLimit"
                      class="form-control"
                      id="withdrawerLimit"
                      required
                    />
                  </InputWrapper>
                </div>
              </div>
              <div class="mb-3">
                <div>
                  <label for="description" class="form-label">Description</label>
                  <InputWrapper :errors="formError.description">
                    <textarea
                      rows="3"
                      type="number"
                      v-model="formData.description"
                      class="form-control"
                      id="description"
                      required
                    />
                  </InputWrapper>
                </div>
              </div>
              <div class="mb-3">
                <label for="requirements" class="form-label">Requirements</label>
                <div class="row row-cols-1 col-md-2 g-0 w-100 gap-md-5 my-3">
                  <div
                    v-if="formData.requirements"
                    class="overflow-auto"
                    style="max-height: 24rem;"
                  >
                    <div
                      v-for="(req, ) in formData.requirements"
                      :key="req"
                      class="d-inline-flex bg-light rounded-md p-3 py-1 m-3 ml-0"
                    >{{ req }}</div>
                  </div>
                </div>
              </div>

              <div class="text-center">
                <button type="submit" class="btn btn-dark">Update KYC setting</button>
              </div>
            </form>
          </div>
          <div v-else>
            <h1 class="text-center">Unable to fetch entry</h1>
            <div class="text-center">
              <button
                type="button"
                class="btn btn-sm btn-outline-primary"
                @click="RefreshData"
              >Retry</button>
            </div>
          </div>
        </div>
      </DualRingLoader>
    </div>
  </div>
</template>
      
      <script lang="ts">
import { defineComponent, onBeforeMount, ref } from "vue";
import { apiGet, apiPut } from "@/api";
import DualRingLoader from "@/components/loader/DualRingLoader.vue";
import { Toast } from "@/utils/notify";
import { useRoute } from "vue-router";
import { ShowSuccess } from "@/utils/notify";
import InputWrapper from "@/components/InputWrapper.vue";

export default defineComponent({
  name: "EditKycEntryForm",
  components: { InputWrapper, DualRingLoader },
  emits: ["save"],
  setup() {
    const formData = ref({
      description: "",
      cummulativeWithdrawerLimit: "",
      dailyWithdrawerLimit: "",
    });
    const loaded = ref(false);
    const formError = ref({});
    const route = useRoute();
    const loading = ref(false);
    async function Submit() {
      loading.value = true;
      try {
        await apiPut(`/admin/kyc/${route.params.kycId}`, {
          description: formData.value.description,
          cummulativeWithdrawerLimit: formData.value.cummulativeWithdrawerLimit,
          dailyWithdrawerLimit: formData.value.dailyWithdrawerLimit,
        });
        ShowSuccess("Entry Updated Successfully");
      } catch (e) {
        formError.value = e?.response?.data?.i ?? {};
        Toast.fire({
          icon: "warning",
          title: "Unable to create entry: " + e.message,
        }).then();
      }
      loading.value = false;
    }

    const RefreshData = async () => {
      loading.value = true;
      try {
        const response = await apiGet(`/admin/kyc`);

        // const responseGetKycInfo = await apiGet(
        //   `/admin/kyc/${route.params.kycId}`
        // );

        // console.log(responseGetKycInfo);

        // console.log(response);

        const kyc = response.data.data.kycsettings;

        let kycObject = kyc.find(
          (entry: { id: string }) => entry.id == route.params.kycId
        );

        formData.value = {
          ...kycObject,
          cummulativeWithdrawerLimit: kycObject.cummulative_withdrawer_limit,
          dailyWithdrawerLimit: kycObject.daily_withdrawer_limit,
        };

        console.log(formData.value);

        // formData.value.code = entry.code;
        // formData.value.name = entry.name;
        // formData.value.usdRate = entry.usd_rate;

        loaded.value = true;
        Toast.fire({
          icon: "success",
          title: "Response fetched",
        }).then();
      } catch (e) {
        Toast.fire({
          icon: "warning",
          title: "Unable to fetch data: " + e.message,
        }).then();
      }
      loading.value = false;
    };

    onBeforeMount(RefreshData);
    return {
      Submit,
      RefreshData,
      loaded,
      formData,
      formError,
      loading,
    };
  },
});
</script>
      
      <style scoped></style>
      