<template>
  <section class="my-5">
    <div class="mb-5 text-center">
      <h1>Edit KYC</h1>
    </div>
    <EditKycEntryForm />
  </section>
</template>
      
      <script lang="ts">
import { defineComponent } from "vue";
import EditKycEntryForm from "@/views/settings/constituent/EditKycEntryForm.vue";
export default defineComponent({
  name: "EditKycEntry",
  components: {
    EditKycEntryForm,
  },
  setup() {
    return {};
  },
});
</script>
      
      <style scoped></style>
      