
import { defineComponent } from "vue";
import EditKycEntryForm from "@/views/settings/constituent/EditKycEntryForm.vue";
export default defineComponent({
  name: "EditKycEntry",
  components: {
    EditKycEntryForm,
  },
  setup() {
    return {};
  },
});
